import { useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import weekday from 'dayjs/plugin/weekday'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import FieldError from '../fieldError/FieldError'
import validateField from '../validation'
import styles from './DatePicker.module.scss'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const subscription = {
  dirtySinceLastSubmit: true,
  error: true,
  submitError: true,
  touched: true,
  value: true
}
const validateFields = []

const DatePickerInput = ({ classname, dateFormat, defaultValue, disabled, label, name, picker, placeHolder, schema, onSelect, ...rest }) => {
  const [value, setValue] = useState('')
  const [localError, setLocalError] = useState(null)

  const { input, meta } = useField(name, {
    subscription,
    validate: schema && validateField(schema),
    validateFields,
    ...rest
  })

  useEffect(() => {
    if (defaultValue && !picker) {
      const date = dayjs(new Date(defaultValue))
      const day = date.date() > 9 ? `${date.date()}` : `0${date.date()}`
      const month = date.month() + 1 > 9 ? `${date.month() + 1}` : `0${date.month() + 1}`
      const val = `${month}/${day}/${date.year()}`
      setValue(date)
      input.onChange(val)
    } else {
      input.onChange('')
      setValue('')
    }
  }, [defaultValue])

  const onChange = (date, dateString) => {
    if (dateString) {
      const selectedDate = dayjs(new Date(dateString)); 
  
      const minDate = dayjs('1900-01-01');
      const maxDate = dayjs();
  
      if (!selectedDate.isValid() || selectedDate.isBefore(minDate) || selectedDate.isAfter(maxDate)) {
        setLocalError('Enter a valid date between January 1, 1900, and today');
        setValue(selectedDate); 
      } else {
        setLocalError(null); 
        setValue(selectedDate); 
        input.onChange(dateString); 
      }
  
      if (onSelect) {
        onSelect(dateString);
      }
    } else {
      onSelect('');
      setValue('');
      setLocalError('Date of Birth is required'); 
      input.onChange('');
    }
  };
  return (
    <>
    <div className={`${styles.input} ${styles[name] ? styles[name] : ''}`}>
        <label htmlFor={input.name} style={{ color: 'black' }}>
          {label}
          <span style={{ color: 'red', marginBottom: '-5rem' }}>*</span>
        </label>
        <DatePicker
          format={dateFormat}
          placeholder={placeHolder || 'MM/DD/YYYY'}
          picker={picker || 'date'}
          style={{
            backgroundColor: '#ffffff',
            position: 'relative'
          }}
          className={localError ? styles.inputFieldError : ''}

          onChange={(_date, dateString) => onChange(_date, dateString)}
          value={value ? dayjs(value) : null} 
        />
         <div className={styles.errorContainer}>
          <FieldError 
            dirtySinceLastSubmit={meta.dirtySinceLastSubmit} 
            error={localError || meta.error}
            submitError={meta.submitError} 
            touched={meta.touched || !!localError}
          />
        </div>
      </div>
    </>
  )
}

DatePickerInput.propTypes = {
  label: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  schema: PropTypes.shape({
    parse: PropTypes.func.isRequired
  }),
  onSelect: PropTypes.func
}

DatePickerInput.defaultProps = {
  schema: undefined,
  onSelect: null
}

export default DatePickerInput
