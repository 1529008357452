const jotFormIds = window.location.hostname === 'forms.hellolunajoy.com' ? {
    'epds': '240811428654456',
    'epds-anxiety': '240872888308469',
    'epds-anxiety-temp': '242453917615460', // EPDS with mismatch
    'gds': '240873846106460',
    'phq9-gad7': '240873252291456',
    'phq9-gad7-temp': '242453194114451', // PHQ9-GAD7 with mismatch
} : {
    'epds': '240811428654456',
    'epds-anxiety': '242451132345447', // EPDS Test without mismatch
    'epds-anxiety-temp': '242452706155454', // EPDS Test with mismatch
    'gds': '240873846106460',
    'phq9-gad7': '242451009588459', // PHQ9-GAD7 Test without mismatch
    'phq9-gad7-temp': '242452761831456', // PHQ9-GAD7 Test with mismatch
}

export default jotFormIds