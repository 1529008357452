import { string, z } from 'zod'

// custom email verification
const isValidEmail = (value) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(value)
}

const validationschema = {
  clinician: z.string({
    required_error: 'Clinician is required.'
  }).min(2).refine((value) => /^[a-zA-Z]+(\.)?([-'\s]?[a-zA-Z]+)*$/.test(value), 'Please select a valid clinician.'),

  contactMethod: string().trim().min(3),

  pregnancyStatus: z.string({
    required_error: 'Pregnancy status is required.'
  }).min(3).refine((value) => /^[a-zA-Z]+([-'\s]?[a-zA-Z]+)*$/.test(value), 'Please select a valid pregnancy status.'),

  insurances: z.array(z.string()).refine((value) => value.length > 0, {
    message: 'Please select atleast one insurance.'
  }),

  emergencyName: z.string().refine((value) => /^[a-zA-Z]+[-'s]?[a-zA-Z ]+$/.test(value) && value.length > 2, { message: 'Invalid name.' }),

  emergencyPhone: z.string()
    .refine((value) => /^\d{10}$/.test(value), {
      message: 'Invalid phone number'
    }),

    email: z.string({ 
      required_error: 'Email address is required.' 
    })
    .nonempty('Email address is required.')
    .email({
      message: (
        <div style={{ lineHeight: '1.5' }}>
          Invalid email. <br />
          Special characters except '+', '-', '.', and '_' are not allowed. Also, the email cannot start or end with a dot, or have consecutive dots.
        </div>
      )
    })
    .refine((value) => {
      const dotRules = /^[^.].*[^.]$/; 
      const consecutiveDotsRule = !/\.\./.test(value); 
      const specialCharRule = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
  
      return dotRules.test(value) && consecutiveDotsRule && specialCharRule;
    }, {
      message: (
        <div style={{ lineHeight: '1.5' }}>
          Invalid email format. <br />
          Ensure there are no dots at the start, end, or consecutively, and only allowed special characters '+', '-', '.', and '_'.
        </div>
      )
    }),  

    firstName: z
    .string({
      required_error: 'First name is required.'
    })
    .nonempty('First name is required.')
    .min(2, 'First name must contain at least 2 characters.') 
    .refine(
      (value) => /^[a-zA-Z]+([-'\s]?[a-zA-Z]+)*$/.test(value),
      'Please enter a valid first name.'
    ), 

  lastName: z
    .string({
      required_error: 'Last name is required.'
    })
    .nonempty('Last name is required.')
    .min(2, 'Last name must contain at least 2 characters.')
    .refine(
      (value) => /^[a-zA-Z]+([-'\s]?[a-zA-Z]+)*$/.test(value),
      'Please enter a valid last name.'
    ),
  

    phone: z
    .string({
      required_error: 'Phone is required.',
    })
    .nonempty('Phone is required.')
    .refine((value) => /^\d{10}$/.test(value), {
      message: 'Invalid phone number',
    }),

  dob: z.string({
    required_error: 'Date of Birth is required.'
  }).refine((value) => {
    // Check if the value matches the MM/DD/YYYY format
    if (!/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(value)) {
      return false
    }
    // Parse the date from the value
    const parts = value.split('/')
    const year = parseInt(parts[2], 10)
    const month = parseInt(parts[0], 10) - 1 // Months are 0-based
    const day = parseInt(parts[1], 10)

    // Create a Date object with the parsed values
    const selectedDate = new Date(year, month, day)

    // Get today's date
    const today = new Date()

    // Create a Date object for January 1, 1900
    const minDate = new Date(1900, 0, 1)

    // Check if the selected date is not before January 1, 1900 and not after today
    return selectedDate >= minDate && selectedDate <= today
  }, {
    message: 'Please enter a valid DOB.'
  })
}

export default validationschema
